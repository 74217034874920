import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import moment from 'moment';
import Empty from '../Empty';

export default function ProductGrid(props) {
  const {products, onItemClick, prefixPath} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  if (prefixPath.indexOf('products') >= 0) {
    // for "products", we use a fully customized version
    return null;
  }

  if (products.length <= 0) {
    return <Empty />;
  }

  if (prefixPath.indexOf('articles') >= 0) {
    return (
      <ArticleGridWrapper mobile={mobile}>
        {products.map((product, i) => (
          <ArticleItem
            key={i}
            mobile={mobile}
            product={product}
            onClick={(evt) => onItemClick(product, evt)}
          />
        ))}
      </ArticleGridWrapper>
    );
  } else if (prefixPath.indexOf('news') >= 0) {
    return (
      <ArticleGridWrapper mobile={mobile}>
        {products.map((product, i) => (
          <ArticleItem
            key={i}
            mobile={mobile}
            product={product}
            onClick={(evt) => onItemClick(product, evt)}
          />
        ))}
      </ArticleGridWrapper>
    );
  }

  return null;
}

const ArticleGridWrapper = styled.div`
  align-self: ${(props) => (props.mobile ? 'center' : 'space-between')};
  padding: ${(props) => (props.mobile ? 0 : 'var(--basePadding)')};
`;

function ArticleItem(props) {
  const {product, onClick, mobile} = props;
  return (
    <ArticleWrapper mobile={mobile} onClick={onClick}>
      <img src={product.image || '/images/logo.png'} alt="article" />

      <div className="info">
        <h3 className="title">{product.title || '無標題'}</h3>
        <p className="date">
          {moment(product.created).format('YYYY / MM / DD HH : mm : ss') || ''}
        </p>
      </div>
    </ArticleWrapper>
  );
}

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  max-width: ${(props) => (props.mobile ? '80%' : 'none')};
  margin: ${(props) => (props.mobile ? '0 auto' : 'unset')};
  margin-top: 30px;
  cursor: pointer;

  & > .info {
    padding: ${(props) => (props.mobile ? 'unset' : '0 20px')};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      letter-spacing: 4px;
      line-height: 1.57;
    }

    & > .date {
      color: var(--primaryColor);
      font-size: 13px;
      letter-spacing: 2px;
      align-self: flex-end;
    }
  }

  & > img {
    flex-basis: ${(props) => (props.mobile ? '200px' : '400px')};
    height: ${(props) => (props.mobile ? '150px' : '250px')};
    max-width: ${(props) => (props.mobile ? 'unset' : '400px')};
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${(props) => (props.mobile ? '10px' : '20px')};
  }
`;
