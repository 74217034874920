import React from 'react';
import styled from 'styled-components';
import ProductListPage from 'rev.sdk.js/Templates/ProductList';
import * as AppActions from '../../AppActions';
import ProductGrid from '../../Components/ProductGrid';
import BrowserHeader from '../../Components/BrowseHeader';

function ProductList(props) {
  let onCustomClick = async (item) => {
    if (props.location.pathname.indexOf('/products') > -1) {
      await AppActions.navigate(`/product?id=${item.id}`, {loading: true});
    } else if (props.location.pathname.indexOf('/articles') > -1) {
      await AppActions.navigate(`/article?id=${item.id}`, {loading: true});
    }
  };

  function renderCustomSection({route, sectionId, params}) {
    if (route === '/articles') {
      if (sectionId === 'A') {
        return <BrowserHeader />;
      }

      if (['B', 'C', 'E'].indexOf(sectionId) > -1) {
        return null;
      }
    } else if (route === '/products') {
      if (['B', 'C', 'E', 'F', 'G', 'H', 'J'].indexOf(sectionId) > -1) {
        return null;
      }

      if (sectionId === 'A') {
        return <BrowserHeader />;
      }
    }
  }

  return (
    <Wrapper id="rev-product-list">
      <ProductListPage
        {...props}
        ProductGrid={ProductGrid}
        onCustomClick={onCustomClick}
        renderCustomSection={renderCustomSection}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 80px;
  max-width: var(--contentMaxWidth);
  margin: 0 auto;

  &#rev-product-list > *:last-child {
    padding-top: 0px;
  }
`;

export default ProductList;
